<template>
  <div :class="['ai-qualified', ai_qualified ? ai_qualified.replace(' ', '_').toLowerCase() : '']" @mouseenter="showTooltip" @mouseleave="hideTooltip">
    {{ ai_qualified }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      ai_qualified: null,
      ai_qualified_reason: null,
      tooltip: null
    };
  },
  mounted() {
    this.ai_qualified = this.params.value;
    this.ai_qualified_reason = this.params?.data?.ai_qualified_reason || this.params?.data?.automate_data?.service_reason;
  },
  methods: {
    showTooltip(event) {
      if (this.ai_qualified_reason) {
        if (!this.tooltip) {
          this.tooltip = document.createElement('div');
          this.tooltip.className = 'ai-qualified-reason-tooltip';
          this.tooltip.textContent = this.ai_qualified_reason;
          document.body.appendChild(this.tooltip);
        }
        this.tooltip.style.display = 'block';
        this.tooltip.style.left = `${event.pageX + 10}px`;
        this.tooltip.style.top = `${event.pageY + 10}px`;
      }
    },
    hideTooltip() {
      if (this.tooltip) {
        document.body.removeChild(this.tooltip);
        this.tooltip = null;
      }
    }
  },
  beforeDestroy() {
    if (this.tooltip) {
      document.body.removeChild(this.tooltip);
    }
  }
};
</script>

<style lang="scss" scoped>
.ai-qualified {
  position: relative;
  cursor: pointer;
  border: 1px solid #EAECF0;
  border-radius: 20px;
  padding: 0 10px;
  color: white;
  height: 90%;
  display: flex;
  align-items: center;
  &.qualified {
    background-color: #12B76A;
  }
  &.unknown, &.repeat_customer {
    color: black;
    background-color: #ffffff;
  }
  &.disqualified {
    background-color: #F25C5C;
  }
}
</style>

<style lang="scss">
.ai-qualified-reason-tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  display: none;
  z-index: 1000;
  pointer-events: none;
  max-width: 200px;
}
</style>
